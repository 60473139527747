import LPContainer from './LPContainer'
import { TypographyH2 } from '~/core/ui/Heading'
import { useTranslation } from 'react-i18next'
import { FCC } from '~/core/@types/global'
import InfiniteMarquee from '~/components/LandingPage/components/InfiniteMarquee'
import Image from 'next/image'
import DotBadge from './components/DotBadge'

const AllPlatformSection: FCC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  return (
    <div className={`${className}`}>
      <InfiniteMarquee />

      <div className="relative">
        <div className="hidden desktop:block w-[1062px] h-[762px] absolute left-1/2 top-0 transform -translate-x-1/2 -translate-y-1">
          <Image
            src={'/img/feature/lp/allplatform_line.svg'}
            alt="all_platform"
            width={1062}
            height={726}
          />
          <div className="h-5/6 w-1 absolute left-1 top-2 overflow-hidden">
            <img
              src="/img/feature/lp/flare.svg"
              alt="flare"
              className="transition delay-1000 animate-moveFlareToBottom"
            />
          </div>
          <div className="h-5/6 w-1 absolute right-0 top-2 overflow-hidden">
            <img
              src="/img/feature/lp/flare.svg"
              alt="flare"
              className="transition-all animate-[moveFlareToBottom_5s_linear_infinite]"
            />
          </div>
        </div>

        <Image
          src={'/img/feature/lp/all_platform_small.png'}
          alt="all_platform_mobile"
          width={375}
          height={349}
          className="absolute top-[463px] desktop:hidden left-1/2 -translate-x-1/2"
        />
        <Image
          src={'/img/feature/lp/all_platform_line.svg'}
          alt="all_platform_line"
          width={9}
          height={41}
          className="absolute top-[32px] left-1/2 desktop:hidden"
        />
      </div>
      <LPContainer className=" flex flex-col items-center">
        <div className="mt-[6rem] tablet:mt-[168px] desktop:mt-[168px] flex-1 flex flex-col gap-4 justify-center max-w-3xl mx-auto items-center text-center">
          <DotBadge
            label={t('lp:all_platform_section:tag')}
            dotClassName="text-[#FFB185]"
          />

          <TypographyH2 className="desktop:max-w-[800px] text-center text-[35px] leading-[40px] tablet:text-[64px] tablet:leading-[64px] font-normal">
            {t('lp:all_platform_section:title')}
          </TypographyH2>
          <span className="desktop:max-w-[700px] text-center tracking-normal">
            {t('lp:all_platform_section:description')}
          </span>
        </div>

        <div className="w-full h-[349px] mt-20 desktop:hidden" />
        <Image
          src={'/img/feature/lp/all_platform.png'}
          alt="all_platform"
          width={848}
          height={522}
          className="mt-[94px] hidden desktop:w-[848px] desktop:block"
        />
      </LPContainer>
    </div>
  )
}

export default AllPlatformSection
