import { useTranslation } from 'react-i18next'
import { FCC } from '~/core/@types/global'

const NUMBER_OF_SECTIONS = 3

const FeatureSection: FCC = () => {
  const { t } = useTranslation()

  return (
    <section className="relative flex flex-col justify-center bg-slate-50">
      <div className="w-full mx-auto pt-[56px] desktop:pt-[88px]">
        <div className="divide-y divide-slate-200 border-t border-b">
          {[...Array(NUMBER_OF_SECTIONS).keys()].map((index) => (
            <div
              key={index}
              className="flex flex-col items-center py-2 sticky top-[80px] bg-white">
              <div
                id={`faqs-title-0${index + 1}`}
                className="flex w-full justify-center text-[31px] leading-[40px] desktop:text-[43px] desktop:leading-[52px] py-2 text-gray-600">
                {t(`lp:feature_section:item_title_${index + 1}`)}
              </div>

              <div
                id={`faqs-text-0${index + 1}`}
                role="region"
                aria-labelledby={`faqs-title-0${index + 1}`}
                className={`max-w-2xl mt-1 grid text-slate-600 overflow-hidden`}>
                <div className="overflow-hidden text-center">
                  <span>{t(`lp:feature_section:item_des_${index + 1}`)}</span>
                  <img
                    src={`/img/feature/lp/feat_${index + 1}.png`}
                    alt="all_platform"
                    loading="lazy"
                    className="mt-10 mb-8"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FeatureSection
